<template>
  <div>
    <div class="tw-flex tw-gap-x-4">
      <FormulateInput
        type="button"
        outer-class="tw-mt-8"
        @click="show"
      >
        <i class="fa fa-plus tw-mr-2" /> Reservatieblok toevoegen
      </FormulateInput>
      <FormulateInput
        v-show="hasAtLeastOneBooking"
        type="button"
        outer-class="tw-mt-8"
        :disabled="generatingList"
        @click="generateVisitsList"
      >
          <i :class="['tw-mr-2', generatingList ? 'fa fa-spinner fa-spin' : 'fa fa-file']" /> {{ generatingList ? 'Lijst genereren ...' : 'Genereer PDF' }}
      </FormulateInput>
    </div>

    <BaseModal ref="modal" title="Reservatieblok toevoegen">
      <FormulateForm
        #default="{ isLoading }"
        v-model="slotData"
        name="propertyCreateSlots"
        @submit="submit"
        class="tw-text-sm md:tw-text-base"
      >
        <div class="tw-max-w-xs tw-grid tw-grid-cols-7 tw-gap-5">
          <FormulateInput
            type="timepicker"
            name="datetime_start"
            label="Startuur"
            :hours="hours()"
            :minutes="minutes()"
            :show-required-label="true"
            validation="bail|required|validTime|fiveMinDifference"
            :validation-rules="{ validTime, fiveMinDifference }"
            :validation-messages="{
              validTime: 'Selecteer een toekomstige tijd.',
              fiveMinDifference: 'Begin- en eindtijd moeten een verschil van ten minste 5 minuten hebben.'
            }"
            outer-class="tw-mt-5 tw-col-span-3"
          />
          <i class="tw-mx-auto tw-mt-14 tw-col-span-1 fa fa-arrow-right" />
          <FormulateInput
            type="timepicker"
            name="datetime_end"
            label="Einduur"
            :hours="hours()"
            :minutes="minutes()"
            :show-required-label="true"
            validation="bail|required|validTime|fiveMinDifference"
            :validation-rules="{ validTime, fiveMinDifference }"
            :validation-messages="{
              validTime: 'Selecteer een toekomstige tijd.',
              fiveMinDifference: 'Begin- en eindtijd moeten een verschil van ten minste 5 minuten hebben.'
            }"
            outer-class="tw-mt-5 tw-col-span-3"
          />
        </div>
        <FormulateInput
          v-model.number="max_bookings"
          type="text"
          inputmode="numeric"
          name="max_bookings"
          value="1"
          label="Maximum aantal boekingen"
          :show-required-label="true"
          validation="bail|required|number|min:1,value"
          validation-name="Dit veld"
        />
        <transition name="fade" mode="out-in">
          <FormulateInput
            v-if="intervals.length"
            key="interval_input"
            type="select"
            name="interval"
            label="Verdeel in reservaties van"
            placeholder="Select interval"
            :options="intervals"
          />
        </transition>

        <transition-group name="fade" mode="out-in">
          <template v-if="slotData.interval">
            <p key="intervalSlotsInfo">
              Er worden
              <span class="tw-text-success tw-font-semibold">
                {{ slots.length }} afspraakblokken
              </span>
              van
              <span class="tw-text-success tw-font-semibold">
                {{ slotData.interval / 60000 }} minuten
              </span>
              aangemaakt.
            </p>
            <details key="intervalSlots">
              <summary class="link tw-font-semibold">
                Bekijk de slots die zullen worden gemaakt
              </summary>
              <ul class="tw-my-2 tw-grid tw-grid-cols-3 tw-grid-flow-row tw-gap-2">
                <li v-for="(slot, index) in slots" :key="index">
                  {{ formatHHmm(slot.datetime_start) }} -
                  {{ formatHHmm(slot.datetime_end) }}
                </li>
              </ul>
            </details>
          </template>
        </transition-group>

        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full lg:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-plus'
            ]"
          />
          Reservatieblok toevoegen
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import { createPropertyTimeSlot } from '@/services/calendarService'
import { formatHHmm, chunks, poll } from '@/utils/helpers'
import { errorModal, successModal } from '@/modalMessages'
import { createDocument, getTemplates, pollGutenborg } from '@/services/gutenborgService'
import { getPropertyVisits } from '@/services/sales'

export default {
  name: 'PropertyVisitCreateSlots',
  props: {
    visitDate: {
      type: Date,
      required: true
    },
    hasAtLeastOneBooking: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      propertyId: this.$route.params.id,
      slotData: {},
      max_bookings: 1,
      generatingList: false
    }
  },
  computed: {
    intervals () {
      let intervals = []
      const start = this.slotData.datetime_start
      const end = this.slotData.datetime_end
      const diff = end - start

      if (diff > 3_600_000) {
        intervals = [
          {
            value: '',
            label: 'Geen interval'
          },
          {
            value: 900_000,
            label: '15 minuten'
          },
          {
            value: 1_200_000,
            label: '20 minuten'
          },
          {
            value: 1_800_000,
            label: '30 minuten'
          },
          {
            value: 2_700_000,
            label: '45 minuten'
          },
          {
            value: 3_600_000,
            label: '60 minuten'
          }
        ]
      }

      return intervals
    },
    slots () {
      let datetime_start = this.visitDate.getTime() + this.slotData.datetime_start
      const datetime_end = this.visitDate.getTime() + this.slotData.datetime_end
      const diff = datetime_end - datetime_start
      const interval = parseInt(this.slotData.interval)

      const slots = []

      if (interval) {
        let i = Math.floor(diff / interval)

        for (i; i > 0; i--) {
          slots.push({
            max_bookings: this.max_bookings,
            property_id: this.propertyId,
            datetime_start: new Date(datetime_start),
            datetime_end: new Date(datetime_start + interval)
          })

          datetime_start = datetime_start + interval
        }
      }

      return slots
    }
  },
  methods: {
    formatHHmm,
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    hours () {
      const hours = []
      for (let i = 8; i < 24; i++) {
        hours.push(i)
      }
      return hours
    },
    minutes () {
      const minutes = []
      for (let i = 0; i < 60; i += 15) {
        minutes.push(i)
      }
      return minutes
    },
    submit (data) {
      const slots = [...this.slots]

      if (this.slots.length) {
        return chunks(
          slots,
          slot => {
            return createPropertyTimeSlot(this.propertyId, slot)
          },
          50
        ).then(() => {
          successModal('Er is een nieuw slot toegevoegd')
          this.hide()
          this.$emit('updateTimeSlots')
        })
      } else {
        const datetime_start = new Date(
          this.visitDate.getTime() + data.datetime_start
        )
        const datetime_end = new Date(this.visitDate.getTime() + data.datetime_end)

        return createPropertyTimeSlot(this.propertyId, {
          datetime_start,
          datetime_end,
          max_bookings: this.max_bookings,
          property_id: this.propertyId
        })
          .then(() => {
            successModal('Er zijn nieuwe slots toegevoegd')
            this.hide()
            this.$emit('updateTimeSlots')
          })
          .catch(error => {
            this.$formulate.handle(error, 'propertyCreateSlots')
          })
      }
    },
    validTime ({ value }) {
      return value + this.visitDate.getTime() > new Date().getTime()
    },
    fiveMinDifference ({ getFormValues }) {
      const values = getFormValues()
      return values.datetime_end - values.datetime_start > 300000
    },
    async generateVisitsList () {
      try {
        this.generatingList = true
        // Date needs to be constructed manually because of timezone issues when using .toISOString()
        // E.g. this.visitDate holds a value of Thu Sep 01 2022 00:00:00 GMT+0200 (Central European Summer Time).
        // When converting this using toISOString the new value would be 2022-08-31T22:00:00.000Z, which is incorrect.
        const dateObj = new Date(Date.UTC(this.visitDate.getFullYear(), this.visitDate.getMonth(), this.visitDate.getDate()))
        const formattedDate = dateObj.toISOString().split('T')[0]
        const templateType = 6 // Visits List Type
        const [templatesResponse, visitsResponse] = await Promise.all([getTemplates(templateType), getPropertyVisits(this.propertyId, formattedDate)])
        const templateId = templatesResponse?.data?.templates?.[0]?.id
        const visitsData = visitsResponse?.data
        const mergeData = btoa(encodeURIComponent(JSON.stringify(
          visitsData)))
        const payload = {
          merge_data: mergeData,
          template_id: templateId,
          config: {
            type: 'pdf',
            destination: 'temp',
            format: 'B64PDF',
            landscape: this.landscape
          }
        }
        const document = await createDocument(payload)
        const pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
        if (pollResult.url) window.open(pollResult.url, '_blank')
        this.generatingList = false
        return pollResult.url
      } catch (error) {
        console.error(error)
        this.generatingList = false
        errorModal('Er ging iets mis bij het genereren van de lijst.')
      }
    }
  }
}
</script>
