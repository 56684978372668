var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex tw-gap-x-4"},[_c('FormulateInput',{attrs:{"type":"button","outer-class":"tw-mt-8"},on:{"click":_vm.show}},[_c('i',{staticClass:"fa fa-plus tw-mr-2"}),_vm._v(" Reservatieblok toevoegen ")]),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAtLeastOneBooking),expression:"hasAtLeastOneBooking"}],attrs:{"type":"button","outer-class":"tw-mt-8","disabled":_vm.generatingList},on:{"click":_vm.generateVisitsList}},[_c('i',{class:['tw-mr-2', _vm.generatingList ? 'fa fa-spinner fa-spin' : 'fa fa-file']}),_vm._v(" "+_vm._s(_vm.generatingList ? 'Lijst genereren ...' : 'Genereer PDF')+" ")])],1),_c('BaseModal',{ref:"modal",attrs:{"title":"Reservatieblok toevoegen"}},[_c('FormulateForm',{staticClass:"tw-text-sm md:tw-text-base",attrs:{"name":"propertyCreateSlots"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-max-w-xs tw-grid tw-grid-cols-7 tw-gap-5"},[_c('FormulateInput',{attrs:{"type":"timepicker","name":"datetime_start","label":"Startuur","hours":_vm.hours(),"minutes":_vm.minutes(),"show-required-label":true,"validation":"bail|required|validTime|fiveMinDifference","validation-rules":{ validTime: _vm.validTime, fiveMinDifference: _vm.fiveMinDifference },"validation-messages":{
            validTime: 'Selecteer een toekomstige tijd.',
            fiveMinDifference: 'Begin- en eindtijd moeten een verschil van ten minste 5 minuten hebben.'
          },"outer-class":"tw-mt-5 tw-col-span-3"}}),_c('i',{staticClass:"tw-mx-auto tw-mt-14 tw-col-span-1 fa fa-arrow-right"}),_c('FormulateInput',{attrs:{"type":"timepicker","name":"datetime_end","label":"Einduur","hours":_vm.hours(),"minutes":_vm.minutes(),"show-required-label":true,"validation":"bail|required|validTime|fiveMinDifference","validation-rules":{ validTime: _vm.validTime, fiveMinDifference: _vm.fiveMinDifference },"validation-messages":{
            validTime: 'Selecteer een toekomstige tijd.',
            fiveMinDifference: 'Begin- en eindtijd moeten een verschil van ten minste 5 minuten hebben.'
          },"outer-class":"tw-mt-5 tw-col-span-3"}})],1),_c('FormulateInput',{attrs:{"type":"text","inputmode":"numeric","name":"max_bookings","value":"1","label":"Maximum aantal boekingen","show-required-label":true,"validation":"bail|required|number|min:1,value","validation-name":"Dit veld"},model:{value:(_vm.max_bookings),callback:function ($$v) {_vm.max_bookings=_vm._n($$v)},expression:"max_bookings"}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.intervals.length)?_c('FormulateInput',{key:"interval_input",attrs:{"type":"select","name":"interval","label":"Verdeel in reservaties van","placeholder":"Select interval","options":_vm.intervals}}):_vm._e()],1),_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.slotData.interval)?[_c('p',{key:"intervalSlotsInfo"},[_vm._v(" Er worden "),_c('span',{staticClass:"tw-text-success tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.slots.length)+" afspraakblokken ")]),_vm._v(" van "),_c('span',{staticClass:"tw-text-success tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.slotData.interval / 60000)+" minuten ")]),_vm._v(" aangemaakt. ")]),_c('details',{key:"intervalSlots"},[_c('summary',{staticClass:"link tw-font-semibold"},[_vm._v(" Bekijk de slots die zullen worden gemaakt ")]),_c('ul',{staticClass:"tw-my-2 tw-grid tw-grid-cols-3 tw-grid-flow-row tw-gap-2"},_vm._l((_vm.slots),function(slot,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(_vm.formatHHmm(slot.datetime_start))+" - "+_vm._s(_vm.formatHHmm(slot.datetime_end))+" ")])}),0)])]:_vm._e()],2),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full lg:tw-w-auto']}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-plus'
          ]}),_vm._v(" Reservatieblok toevoegen ")])]}}]),model:{value:(_vm.slotData),callback:function ($$v) {_vm.slotData=$$v},expression:"slotData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }